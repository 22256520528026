<template>
  <div class="input-wrapper">
    <input
      @input="handleInput"
      class="form-control"
      :class="cssClass"
      :required="required"
      :autofocus="autofocus"
      :type="type"
      placeholder=" "
      :small_text="small_text"
      :value="value"
      :disabled="disabled"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
    />

    <label>{{ placeholder }} <span v-if="small_text">{{ small_text }}</span></label>

    <div class="invalid-feedback">
      {{ validationMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cssClass: null,
    required: Boolean,
    autofocus: Boolean,
    placeholder: String,
    small_text: String,
    label: String,
    validationMessage: String,
    type: String,
    value: String,
    disabled: Boolean,
    maxlength: String,
    autocomplete: String
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.input-wrapper {
  position: relative;
  margin-top: 35px;
}

.form-control {
  height: 50px;
  padding: 10px 10px 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--pale-grey-battleship-grey-three);
  font-size: 14px;
  font-weight: 300;
  color: var(--charcoal-grey-white);
  background-color: var(--white-charcoal-grey);
  line-height: 50px;

  &.is-invalid {
    border: 1px solid var(--light-peach) !important;
    background-image: none;

    &:focus {
      border-color: var(--light-peach);
      box-shadow: none;
    }
  }
}

.invalid-feedback {
  position: absolute;
  margin-top: 0;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 300;
  color: var(--dark-coral);
}

label {
  position: absolute;
  top: 17px;
  left: 17px;
  display: block;
  width: 100%;
  line-height: 16px;
  font-size: 14px;
  color: var(--silver);
  transition: all .3s ease-in-out;
  pointer-events: none;
  font-weight: 300;

  span {
    font-size: 10px;
  }
}

::placeholder {
  color: transparent;
  opacity: 1;
}

input:not(:placeholder-shown) ~ label {
  top: -25px;
  left: 0;
  font-size: 14px;
  line-height: 17px;
  color: var(--charcoal-grey-white);
  pointer-events: initial;
}
</style>
